import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div id="about">
      <section className="story">
        <h1>Isaac. A Brief Intro.</h1>
        <p>
        I always have a bit of a difficult time picking an exact description of 
        the kind of work I've done over the years. I usually describe myself as 
        an Interactive Developer. My career path has taken me from full-stack 
        web development to video game engineering to writing software for 
        digital innovation and marketing agencies.
        </p>
        <p>
        Recently I've been using technology to bring to life built environments.
        From trade shows to museums to retail spaces and customer/corporate 
        briefing centers, anywhere that a client wants to transform the 
        experience of their guest and employees in their spaces. Some of the 
        clients I have worked with include; NFL Networks, Salesforce, Cisco, 
        Visa, GSK, Gensler, the City of San Francisco, and Madison Square 
        Garden.
        </p>
        <p>
        A lot of my development efforts have been focused around building 
        innovative informational kiosks, presentation tools, interactive 
        experiences, and building systems that react to, and provide information
        about, the people in these environments. These development efforts have 
        been guided by a collection of amazing designers, PMs and account 
        managers that I've had the privilege of collaborating with. My 
        individual contributions have spanned all aspects of programming; 
        graphics, interaction and UI programming, audio, lighting, sensor 
        integration, and backend systems. I've also been involved with 
        specifying requirements, interacting with outside vendors and 
        contractors, and on-site installation.
        </p>
        <p>
        Ultimately what really drives me is building amazing experiences with a 
        talented and compassionate team that delight the people that interact 
        with our work.
        </p>
      </section>
      <section className="skills">
        <h1>Skills and Technologies</h1>

        <h2>Areas of Expertise</h2>
        <ul>
          <li>Interactive Installation Development</li>
          <li>Realtime Graphics</li>
          <li>Data Visualization</li>
        </ul>

        <h2>Secondary Expertise</h2>
        <ul>
          <li>Web Site Development</li>
          <li>Mobile Applications</li>
          <li>Game Development</li>
          <li>Web Application Development</li>
        </ul>

        <h2>Technology &amp; Platform Expertise</h2>
        <ul>
          <li>TouchDesigner</li>
          <li>Unity</li>
          <li>Cinder</li>
          <li>OpenFrameworks</li>
          <li>Processing</li>
          <li>C/C++</li>
          <li>C#</li>
          <li>Python</li>
          <li>Javascript</li>
          <li>Node</li>
          <li>THREE.js</li>
          <li>D3.js</li>
          <li>React</li>
          <li>Git</li>
        </ul>
        <h2>Other Technology Familiarity</h2>
        <ul>
          <li>Java</li>
          <li>PHP</li>
          <li>Ruby</li>
          <li>SQL Databases</li>
          <li>NoSQL Databases</li>
          <li>Adobe Software</li>
        </ul>
      </section>
    </div>
  </Layout>
)

export default AboutPage
